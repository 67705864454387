import React from 'react';
import { ILoaderCircle } from './ILoaderCircle';
import styles from './LoaderCircle.module.scss';

const LoaderCircle: React.FC<ILoaderCircle> = ({ value }) => (
  <svg viewBox="0 0 38 38" className={styles.circle}>
    <circle
      className="donut-hole"
      cx="50%"
      cy="50%"
      r="15.91549430918954"
      fill="transparent"
    >
    </circle>
    <circle
      className="donut-ring"
      cx="50%"
      cy="50%"
      r="15.91549430918954"
      fill="transparent"
      stroke="url(#paint0_linear)"
      strokeWidth="2"
    >
    </circle>
    <circle
      className="donut-segment"
      cx="50%"
      cy="50%"
      r="15.91549430918954"
      fill="transparent"
      stroke="black"
      opacity="0.3"
      strokeWidth="2"
      strokeDasharray={`${100 - value} ${value}`}
    >
    </circle>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="45"
        y2="-9.5"
        x2="53.1842"
        y1="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2699A0" />
        <stop offset="1" stopColor="#C6AF5D" />
      </linearGradient>
    </defs>
  </svg>
);

export default LoaderCircle;
