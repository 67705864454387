import React from 'react';
import { ReactComponent as Img } from 'src/assets/images/quiz/trust.svg';
import QuizContent from 'src/components/QuizContent/QuizContent';
import RadioChoice from 'src/components/RadioChoice/RadioChoice';
import { ROUTES } from '../../constants/routes';
import PensiveImg from '../../assets/images/emojies/pensive.svg';
import NeutralImg from '../../assets/images/emojies/neutral.svg';
import RelievedImg from '../../assets/images/emojies/relieved.svg';

const choices = [
  { text: 'Totally yes', img: PensiveImg, id: 1 },
  { text: 'Maybe, sometimes', img: NeutralImg, id: 2 },
  { text: 'Not really', img: RelievedImg, id: 3 },
];

const Trust = () => (
  <QuizContent
    title="Do you enjoy having a wide circle of acquaintances?"
    img={<Img title="trust" />}
    step={4}
    radio
    routeBack={ROUTES.DETAILS}
  >
    <RadioChoice route={ROUTES.MAIL} choices={choices} name="trust" />
  </QuizContent>
);
export default Trust;
