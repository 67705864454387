import React from 'react';

import styles from './Title.module.scss';

const Title = () => (
  <h3 className={styles.title}>
    Your personalized astrology powered compatibility report for CANCER
    is ready
  </h3>
);

export default Title;
