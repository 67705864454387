import React from 'react';

import styles from './PackageDescription.module.scss';

const contents = [
  'Compatibility report for CANCER',
  'Accurate instructions on how to improve relationship with people around you',
  'Practical relationship advice for your zodiac sign',
  'Full horoscope for 2022 with peredictions, do’s and don’ts',
  'Premium account in Astrology App',
];

const PackageDescription = () => (
  <div className={styles.container}>
    <h3 className={styles.title}>What’s inside?</h3>
    <ul className={styles.list}>
      {contents.map((text) => (
        <li key={text} className={styles.list_item}>
          {text}
        </li>
      ))}
    </ul>
  </div>
);

export default PackageDescription;
