import React from 'react';
import address from 'src/assets/images/address.svg';

import styles from './Address.module.scss';

const Address = () => (
  <div className={styles.wrapper}>
    <img className={styles.image} src={address} alt="address" />
  </div>
);

export default Address;
