import React from 'react';
import { useCookiesAgreement } from 'src/hooks/useCookiesAgreement';
import InlineLink from 'src/components/common/InlineLink';
import { ReactComponent as Img } from 'src/assets/images/quiz/taurus.svg';
import Link from 'src/components/common/Link/Link';
import { ROUTES } from 'src/constants/routes';
import styles from './Flow.module.scss';
import useFlow from './useFlow';

const Flow = () => {
  useFlow();
  const { isPrivateDataCollectionAgreed } = useCookiesAgreement();

  return (
    <div className={styles.container}>
      <div>
        <h1 className={styles.title}>
          Finding someone you&apos;re truly compatible with in many ways isn&apos;t easy.
          Are you 100% sure that you surround yourself with the right people?
        </h1>
        <p className={styles.text}>
          Take a short quiz to make sure that your beloved is a right one and get a full compatibility report for CANCER
        </p>
      </div>
      <Img className={styles.img} title="partners" />
      <div className={styles.link_container}>
        <Link
          text="Check compatibility"
          to={ROUTES.GENDER}
          className={styles.link}
          disabled={!isPrivateDataCollectionAgreed}
        />
        <p className={styles.policy}>
          By continuing I agree with
          {' '}
          <InlineLink className={styles.inline_link} href={ROUTES.TERMS}>
            Terms of Service
          </InlineLink>
          {' '}
          and
          {' '}
          <InlineLink
            className={styles.inline_link}
            href={ROUTES.PRIVACY_POLICY}
          >
            Privacy Policy
          </InlineLink>
          .
        </p>
      </div>
    </div>
  );
};

export default Flow;
