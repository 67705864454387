import React from 'react';
import styles from './Advantages.module.scss';
import Button from '../../common/Button/Button';
import AdvantagesList from '../AdvantagesList/AdvantagesList';
import scrollToBlock from '../../../utils/scrollToBlock';

const Advantages = () => {
  const onGetMyReport = () => {
    scrollToBlock('package');
  };

  return (
    <div>
      <h2 className={styles.title}>Why ECLIPSE is right for you?</h2>
      <AdvantagesList />
      <div className={styles.button_container}>
        <Button
          text="Get my report"
          onClick={onGetMyReport}
          className={styles.button}
          wrapperClassName={styles.button_wrapper}
        />
      </div>
    </div>
  );
};

export default Advantages;
