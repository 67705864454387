/* eslint-disable quotes,react/no-unescaped-entities */
import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import { ReactComponent as Address } from 'src/assets/images/address.svg';
import styles from './Terms.module.css';

const Terms = () => (
  <div className={styles.container}>
    <h1>TERMS AND CONDITIONS OF USE</h1>
    <p>
      <b>THIS SERVICE MAY INCLUDE SUBSCRIPTIONS THAT AUTOMATICALLY RENEW.</b>
      {" "}
      PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY (IN PARTICULAR,
      SECTION 5 “FEES AND PAYMENT”) BEFORE STARTING A TRIAL OR COMPLETING A
      PURCHASE FOR OUR AUTO-RENEWING SUBSCRIPTION SERVICE. TO
      {" "}
      <b>AVOID BEING CHARGED</b>
      {" "}
      YOU MUST AFFIRMATIVELY REQUEST CANCELLATION A SUBSCRIPTION OR
      A TRIAL
      {" "}
      <b>
        AT LEAST 24 HOURS BEFORE THE END OF THE TRIAL OR THEN-CURRENT
        SUBSCRIPTION PERIOD
      </b>
      .
    </p>
    <p>
      IF YOU ARE UNSURE HOW TO CANCEL A SUBSCRIPTION OR A TRIAL, PLEASE VISIT
      THE
      {" "}
      <a href="https://support.apple.com/en-us/HT202039">APPLE SUPPORT</a>
      {" "}
      WEBSITE,
      {" "}
      <a href="https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en">
        GOOGLE PLAY HELP
      </a>
      {" "}
      (OR ANY OTHER APP STORES’ SUPPORT PAGES) OR FOLLOW INSTRUCTIONS FOR
      CANCELLATION IN OUR SUBSCRIPTION TERMS. YOU MAY WISH TO TAKE A SCREENSHOT
      OF THIS INFORMATION FOR YOUR REFERENCE. DELETING THE APP DOES NOT CANCEL
      YOUR SUBSCRIPTIONS AND TRIALS.
    </p>
    <br />
    <h2>1. ACCEPTANCE OF TERMS</h2>
    <p>
      1.1. Mobile application Nebula (the "App"), Nebula website available at
      {" "}
      <a href="https://stars-know.com/">https://stars-know.com/</a>
      {" "}
      (the “Website”)
      and content available via the App and the Website, or our emails
      ("Content") are distributed by Obrio Limited (having its mailing address at:
      {' '}
      <Address className={styles.address} title="address" />
      ) or
      other company as stated in an app store ("we" "us" "our" or the
      "Company"). The App, the Website, together with the Content, tools,
      transactions and other services available by using them, are collectively
      referred to as the "Service".
    </p>
    <p>
      1.2. Your access and use of the Service constitutes your agreement to be
      bound by these Terms and Conditions of Use (the "Terms"), which
      establishes a legally binding contractual relationship between you and the
      Company. For this reason, PLEASE READ THE TERMS CAREFULLY BEFORE USING THE
      SERVICE.
    </p>
    <p>
      1.3. Please review also our
      {" "}
      <a href="#privacy-policy">Privacy Policy</a>
      .
      The terms of the Privacy Policy and other supplemental terms, policies or
      documents that may be posted on the Service from time to time are hereby
      expressly incorporated herein by reference. We reserve the right, in our
      sole discretion, to make changes or modifications to these Terms at any
      time and for any reason.
    </p>
    <p>
      1.4. Unless otherwise expressly provided herein, we will alert you about
      any changes by updating the "Last updated" date of these Terms and you
      waive any right to receive specific notice of each such change.
    </p>
    <p>
      1.5. THESE TERMS CONTAIN IMPORTANT DISCLAIMERS (SECTION 2), DISCLAIMERS OF
      WARRANTIES (SECTION 7), LIMITATION OF LIABILITY (SECTION 8), AS WELL AS
      PROVISIONS THAT WAIVE YOUR RIGHT TO A JURY TRIAL, RIGHT TO A COURT HEARING
      AND RIGHT TO PARTICIPATE IN A CLASS ACTION (ARBITRATION AND CLASS ACTION
      WAIVER). UNLESS YOU OPT OUT WITHIN 30 DAYS OF FIRST USE OF OUR SERVICE AS
      PROVIDED FOR IN SECTION 12, ARBITRATION IS THE EXCLUSIVE REMEDY FOR ANY
      AND ALL DISPUTES AND IS MANDATORY EXCEPT AS SPECIFIED BELOW IN SECTION 11.
    </p>
    <p>
      1.6. IF YOU DO NOT AGREE WITH ANY PART OF THESE TERMS, OR IF YOU ARE NOT
      ELIGIBLE OR AUTHORIZED TO BE BOUND BY THESE TERMS, THEN DO NOT DOWNLOAD
      THE APP OR OTHERWISE ACCESS OR USE THE SERVICE.
    </p>
    <br />
    <h2>2. IMPORTANT DISCLAIMERS</h2>
    <p>
      2.1. WE MAKE NO GUARANTEES THAT (I) THE SERVICE WILL MEET YOUR
      REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
      ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
      SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF ANY PRODUCTS,
      SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
      THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS OR WILL PROVIDE ANY
      BENEFIT.
    </p>
    <p>
      2.2. NOT ALL INFORMATION DESCRIBED IN THE SERVICE IS SUITABLE FOR
      EVERYONE. THE SERVICE IS INTENDED ONLY AS A TOOL WHICH MAY BE USEFUL IN
      ACHIEVING YOUR OVERALL LIFESTYLE GOALS. YOU ACKNOWLEDGE THAT IF YOUR
      ACTIVITIES ENCOURAGED OR INSPIRED BY THE SERVICE INVOLVE ANY RISKS, YOU
      ASSUME THOSE RISKS AND UNDERSTAND AND AGREE THAT YOU TAKE FULL
      RESPONSIBILITY FOR YOUR HEALTH, LIFE AND WELL-BEING, AS WELL AS THE
      HEALTH, LIVES AND WELL-BEING OF YOUR FAMILY AND CHILDREN (BORN AND UNBORN,
      AS APPLICABLE), AND ALL DECISIONS NOW OR IN THE FUTURE.
    </p>
    <p>
      2.3. INFORMATION PROVIDED THROUGH OUR SERVICE IS FOR INFORMATIONAL AND
      ENTERTAINMENT PURPOSES ONLY. THE SERVICE IS NOT INTENDED TO BE A
      SUBSTITUTE FOR ANY PROFESSIONAL ADVICE, INCLUDING BUT NOT LIMITED TO (A)
      PROFESSIONAL MEDICAL OR PSYCHIATRIC ADVICE, DIAGNOSIS, OR TREATMENT, OR
      (B) PROFESSIONAL FINANCIAL OR INVESTMENT ADVICE OR GUIDANCE, OR (C)
      PROFESSIONAL LEGAL ADVICE. NEVER DISREGARD OR DELAY SEEKING PROFESSIONAL
      MEDICAL ADVICE OR OTHER PROFESSIONAL ADVICE. YOUR RELIANCE ON THE
      INFORMATION PROVIDED BY THE SERVICE IS SOLELY AT YOUR OWN ELECTION OR
      CHOICE. ANY AND ALL DECISIONS THAT YOU MAKE THAT ARE BASED IN WHOLE OR IN
      PART UPON INFORMATION PROVIDED BY THE SERVICE WILL BE YOUR SOLE AND
      EXCLUSIVE RESPONSIBILITY.
    </p>
    <br />
    <h2>3. SERVICE</h2>
    <p>
      3.1. You acknowledge that all the text, images, marks, logos, compilations
      (meaning the collection, arrangement and assembly of information), data,
      other content, software and materials displayed on the Service or used by
      the Company to operate the Service (including the App, the Website and the
      Content and excluding any User Content (as defined below)) is proprietary
      to us or to third parties.
    </p>
    <p>
      3.2. The Company expressly reserves all rights, including all intellectual
      property rights, in all of the foregoing, and except as expressly
      permitted by these Terms, any use, redistribution, sale, decompilation,
      reverse engineering, disassembly, translation or other exploitation of
      them is strictly prohibited. The provision of the Service does not
      transfer to you or any third party any rights, title or interest in or to
      such intellectual property rights.
    </p>
    <p>
      3.3. The information you submit to us as part of your registration, and
      any data, text and other material that you may submit or post to the
      Service ("User Content") remain your intellectual property, and the
      Company does not claim any ownership of the copyright or other proprietary
      rights in such registration information and the User Content.
      Notwithstanding the foregoing, you agree that the Company may retain
      copies of all registration information and the User Content and use such
      information and the User Content as reasonably necessary for or incidental
      to its operation of the Service and as described in these Terms and the
      Privacy Policy.
    </p>
    <p>
      3.4. You grant the Company the non-exclusive, worldwide, transferable,
      perpetual, irrevocable right to publish, distribute, publicly display and
      perform the User Content in connection with the Service.
    </p>
    <p>
      3.5. Subject to these Terms, the Company grants you a non-transferable,
      non-exclusive, license (without the right to sublicense) to use the
      Service solely for your personal, non-commercial purposes.
    </p>
    <p>
      3.6. You agree, and represent and warrant, that your use of the Service,
      or any portion thereof, will be consistent with the foregoing license,
      covenants and restrictions and will neither infringe nor violate the
      rights of any other party or breach any contract or legal duty to any
      other parties. In addition, you agree that you will comply with all
      applicable laws, regulations and ordinances relating to the Service or
      your use of it, and you will be solely responsible for your own individual
      violations of any such laws.
    </p>
    <p>
      3.7. You are solely responsible for obtaining the equipment and
      telecommunication services necessary to access the Service, and all fees
      associated therewith (such as computing devices and Internet service
      provider and airtime charges).
    </p>
    <p>
      3.8. We retain the right to implement any changes to the Service (whether
      to free or paid features) at any time, with or without notice. You
      acknowledge that a variety of Company's actions may impair or prevent you
      from accessing the Service at certain times and/or in the same way, for
      limited periods or permanently, and agree that the Company has no
      responsibility or liability as a result of any such actions or results,
      including, without limitation, for the deletion of, or failure to make
      available to you, any content or services.
    </p>
    <p>
      3.9. Your access to and use of the Service is at your own risk. The
      Company will have no responsibility for any harm to your computing system,
      loss of data, or other harm to you or any third party, including, without
      limitation, any bodily harm, that results from your access to or use of
      the Service, or reliance on any information or advice.
    </p>
    <p>
      3.10. The Company has no obligation to provide you with customer support
      of any kind. However, the Company may provide you with customer support
      from time to time, at the Company's sole discretion.
    </p>
    <br />
    <h2>4. APP STORES, THIRD PARTY ADS, OTHER USERS</h2>
    <p>
      4.1. You acknowledge and agree that the availability of the App is
      dependent on the third party from which you received the App, e.g., the
      Apple iTunes App Store, Google Play, and/or other app stores
      (collectively, "App Stores" and each, an "App Store").
    </p>
    <p>
      4.2. You agree to pay all fees charged by the App Stores in connection
      with the App. You agree to comply with, and your license to use the App is
      conditioned upon your compliance with, all applicable agreements, terms of
      use/service, and other policies of the App Stores. You acknowledge that
      the App Stores (and their subsidiaries) are a third party beneficiary of
      these Terms and will have the right to enforce these Terms.
    </p>
    <p>
      4.3. The Service may contain links to third party websites or resources
      and advertisements for third parties (collectively, "Third Party Ads").
      Such Third Party Ads are not under the control of the Company and the
      Company is not responsible for any Third Party Ads. The Company provides
      these Third Party Ads only as a convenience and does not review, approve,
      monitor, endorse, warrant, or make any representations with respect to
      Third Party Ads. Advertisements and other information provided by Third
      Party Sites Ads may not be wholly accurate. You acknowledge sole
      responsibility for and assume all risk arising from your use of any such
      websites or resources. When you link to a third party site, the applicable
      service provider's terms and policies, including privacy and data
      gathering practices govern. You should make whatever investigation you
      feel necessary or appropriate before proceeding with any transaction with
      any third party. Your transactions and other dealings with Third Party Ads
      that are found on or through the Service, including payment and delivery
      of related goods or services, are solely between you and such merchant or
      advertiser.
    </p>
    <p>
      4.4. Each user of the Service is solely responsible for any and all his or
      her User Content. Because we do not control the User Content, you
      acknowledge and agree that we are not responsible for any User Content and
      we make no guarantees regarding the accuracy, currency, suitability, or
      quality of any User Content, and we assume no responsibility for any User
      Content. Your interactions with other Service users are solely between you
      and such user. You agree that the Company will not be responsible for any
      loss or damage incurred as the result of any such interactions. If there
      is a dispute between you and any Service user, we are under no obligation
      to become involved.
    </p>
    <p>
      4.5. You hereby release us, our officers, employees, agents and successors
      from claims, demands any and all losses, damages, rights, claims, and
      actions of any kind including personal injuries, death, and property
      damage, that is either directly or indirectly related to or arises from
      any interactions with or conduct of any App Store, any other Service
      users, or any Third Party Ads.
    </p>
    <br />
    <h2>5. FEES AND PAYMENT</h2>
    <p>5.1. Certain features of the Service are offered for a fee.</p>
    <p>
      5.2. To the maximum extent permitted by applicable laws, we may change
      fees at any time. We will give you reasonable notice of any such pricing
      changes by posting the new prices on or through the Service or by sending
      you an email notification.
    </p>
    <p>
      5.3. You authorize the App Stores and us to charge the applicable fees to
      the payment card that you submit.
    </p>
    <p>
      5.4. When you purchase certain subscriptions, you agree that your
      subscription may be automatically renewed. Unless you cancel your
      subscription, you authorize the App Store and us to charge you for the
      renewal term. The period of auto-renewal will be the same as your initial
      subscription period unless otherwise disclosed to you on the Service
      before purchase. The renewal rate will be no more than the rate for the
      immediately prior subscription period, excluding any promotional and
      discount pricing, unless we notify you of a rate change prior to your
      auto-renewal. You must cancel your subscription in accordance with the
      cancellation procedures disclosed to you for the particular subscription.
    </p>
    <p>
      5.5. We may offer a trial subscription for the Service. Trial provides you
      access to the Service for a period of time, with details specified when
      you sign up for the offer. Unless you cancel before the end of the trial,
      or unless otherwise stated, your access to the Service will automatically
      continue and you will be billed the applicable fees for the Service. We
      may send you a reminder when your trial is about to end, but we do not
      guarantee any such notifications. It is ultimately your responsibility to
      know when the trial will end. We reserve the right, in our absolute
      discretion, to modify or terminate any trial offer, your access to the
      Service during the trial, or any of these terms without notice and with no
      liability. We reserve the right to limit your ability to take advantage of
      multiple trials.
    </p>
    <p>
      5.6. The Service and your rights to use it expire at the end of the paid
      period of your subscription. If you do not pay the fees or charges due, we
      may make reasonable efforts to notify you and resolve the issue; however,
      we reserve the right to disable or terminate your access to the Service
      (and may do so without notice).
    </p>
    <p>
      5.7. Purchases made via an App Store are subject to such App Store's
      refund policies. This means we cannot grant refunds. You will have to
      contact the respective App Store’s support. Purchases made via the Website
      are subject to our refund policy described below. Generally, the fees you
      have paid are non-refundable and/or non-exchangeable, unless otherwise is
      stated herein, on the Service or is required by applicable law. Note for
      the EU residents: If you are an EU resident, you have the right to
      withdraw from agreement for purchase of digital content without charge and
      without giving any reason within fourteen (14) days from the date of such
      agreement conclusion. The withdrawal right does not apply if the
      performance of the agreement has begun with your prior express consent and
      your acknowledgment that you thereby lose your right of withdrawal. YOU
      HEREBY EXPRESSLY CONSENT TO THE IMMEDIATE PERFORMANCE OF THE AGREEMENT AND
      ACKNOWLEDGE THAT YOU WILL LOSE YOUR RIGHT OF WITHDRAWAL FROM THE AGREEMENT
      ONCE OUR SERVERS VALIDATE YOUR PURCHASE AND THE APPLICABLE PURCHASE IS
      SUCCESSFULLY DELIVERED TO YOU. Therefore, you will not be eligible to a
      refund, unless the digital content is defective.
    </p>
    <br />
    <h2>6. USER REPRESENTATIONS AND RESTRICTIONS</h2>
    <p>6.1. By using the Service, you represent and warrant that:</p>
    <p>
      6.1.1. you have the legal capacity and you agree to comply with these
      Terms;
    </p>
    <p>6.1.2. you are not under the age of 18;</p>
    <p>
      6.1.3. you will not access the Service through automated or non-human
      means, whether through a bot, script or otherwise;
    </p>
    <p>
      6.1.4. you will not use the Service for any illegal or unauthorized
      purpose;
    </p>
    <p>
      6.1.5. you are not located in a country that is subject to a U.S.
      government embargo, or that has been designated by the U.S. government as
      a "terrorist supporting" country;
    </p>
    <p>
      6.1.6. you are not listed on any U.S. government list of prohibited or
      restricted parties; and
    </p>
    <p>
      6.1.7. your use of the Service will not violate any applicable law or
      regulation.
    </p>
    <p>
      6.2. If you provide any information that is untrue, inaccurate, not
      current, or incomplete, we have the right to refuse any and all current or
      future use of the Service (or any portion thereof).
    </p>
    <p>
      6.3. You may not access or use the Service for any purpose other than that
      for which we make the Service available. The Service may not be used in
      connection with any commercial endeavors except those that are
      specifically endorsed or approved by us.
    </p>
    <p>6.4. As a user of the Service, you agree not to:</p>
    <p>
      6.4.1. systematically retrieve data or other content from the Service to
      create or compile, directly or indirectly, a collection, compilation,
      database, or directory without written permission from us;
    </p>
    <p>6.4.2. make any unauthorized use of the Service;</p>
    <p>
      6.4.3. make any modification, adaptation, improvement, enhancement,
      translation, or derivative work from the Service;
    </p>
    <p>
      {" "}
      6.4.4. use the Service for any revenue generating endeavor, commercial
      enterprise, or other purpose for which it is not designed or intended;
    </p>
    <p>
      6.4.5. make the Service available over a network or other environment
      permitting access or use by multiple devices or users at the same time;
    </p>
    <p>
      6.4.6. use the Service for creating a product, service, or software that
      is, directly or indirectly, competitive with or in any way a substitute
      for the Service;
    </p>
    <p>
      6.4.7. use any proprietary information or any of our interfaces or our
      other intellectual property in the design, development, manufacture,
      licensing, or distribution of any applications, accessories, or devices
      for use with the Service;
    </p>
    <p>
      6.4.8. circumvent, disable, or otherwise interfere with security-related
      features of the Service;
    </p>
    <p>6.4.9. engage in unauthorized framing of or linking to the Service;</p>
    <p>
      6.4.10. interfere with, disrupt, or create an undue burden on the Service
      or the networks or services connected to the Service;
    </p>
    <p>
      {" "}
      6.4.11. decipher, decompile, disassemble, or reverse engineer any of the
      software comprising or in any way making up a part of the Service;
    </p>
    <p>
      6.4.12. attempt to bypass any measures of the Service designed to prevent
      or restrict access to the Service, or any portion of the Service;
    </p>
    <p>
      6.4.13. upload or distribute in any way files that contain viruses, worms,
      trojans, corrupted files, or any other similar software or programs that
      may damage the operation of another's computer;
    </p>
    <p>
      6.4.14. use, launch, develop, or distribute any automated system,
      including without limitation, any spider, robot, cheat utility, scraper,
      or offline reader that accesses the Service, or using or launching any
      unauthorized script or other software;
    </p>
    <p>
      6.4.15. use the Service to send automated queries to any website or to
      send any unsolicited commercial e-mail;
    </p>
    <p>
      6.4.16. disparage, tarnish, or otherwise harm, in our opinion, us and/or
      the Service;
    </p>
    <p>
      6.4.17. use the Service in a manner inconsistent with any applicable laws
      or regulations; or
    </p>
    <p>6.4.18. otherwise infringe these Terms.</p>
    <br />
    <h2>7. DISCLAIMER OF WARRANTIES</h2>
    <p>
      {" "}
      THE APP, THE WEBSITE, THE CONTENT AND OTHER ASPECTS OF THE SERVICE ARE
      PROVIDED "AS IS" AND "AS AVAILABLE". THE APP, THE WEBSITE, THE CONTENT AND
      OTHER ASPECTS OF THE SERVICE ARE PROVIDED WITHOUT REPRESENTATION OR
      WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
      THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, INTEGRATION,
      MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES
      IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE
      EXPRESSLY DISCLAIMED. THE COMPANY AND ITS AFFILIATES, LICENSORS AND
      SUPPLIERS DO NOT WARRANT THAT: (I) THE SERVICE, CONTENT OR OTHER
      INFORMATION WILL BE TIMELY, ACCURATE, RELIABLE OR CORRECT; (II) THE
      SERVICE WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR PLACE; (III)
      ANY DEFECTS OR ERRORS WILL BE CORRECTED; (IV) THE SERVICE WILL BE FREE OF
      VIRUSES OR OTHER HARMFUL COMPONENTS; OR (IV) ANY RESULT OR OUTCOME CAN BE
      ACHIEVED.
    </p>
    <br />
    <h2>8. LIMITATION OF LIABILITY</h2>
    <p>
      8.1. IN NO EVENT SHALL WE (AND OUR AFFILIATES) BE LIABLE TO YOU OR ANY
      THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
      INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM THESE TERMS OR YOUR
      USE OF, OR INABILITY TO USE, THE SERVICE (INCLUDING THE APP, THE WEBSITE,
      OR CONTENT), OR THIRD PARTY ADS, EVEN IF WE HAVE BEEN ADVISED OF THE
      POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SERVICE (INCLUDING
      THE APP, THE WEBSITE, THE CONTENT AND USER CONTENT), AND THIRD PARTY ADS
      ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE
      FOR ANY DAMAGE TO YOUR COMPUTING SYSTEM OR LOSS OF DATA RESULTING
      THEREFROM.
    </p>
    <p>
      8.2. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, YOU AGREE
      THAT THE AGGREGATE LIABILITY OF THE COMPANY TO YOU FOR ANY AND ALL CLAIMS
      ARISING FROM THE USE OF THE APP, THE WEBSITE, THE CONTENT OR SERVICE IS
      LIMITED TO THE AMOUNTS YOU HAVE PAID TO THE COMPANY FOR ACCESS TO AND USE
      OF THE SERVICE. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
      ELEMENTS OF THE BASIS OF THE TERMS BETWEEN THE COMPANY AND YOU.
    </p>
    <p>
      8.3. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
      LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION
      OR EXCLUSION MAY NOT APPLY TO YOU AND YOU MAY ALSO HAVE OTHER LEGAL RIGHTS
      THAT VARY FROM JURISDICTION TO JURISDICTION.
    </p>
    <br />
    <h2>9. INDEMNITY</h2>
    <p>
      You agree to indemnify and hold the Company, its successors, subsidiaries,
      affiliates, any related companies, its suppliers, licensors and partners,
      and the officers, directors, employees, agents and representatives of each
      of them harmless, including costs and attorneys' fees, from any claim or
      demand made by any third party due to or arising out of (i) your use of
      the Service, (ii) your User Content, or (iii) your violation of these
      Terms. The Company reserves the right, at your expense, to assume the
      exclusive defense and control of any matter for which you are required to
      indemnify us and you agree to cooperate with our defense of these claims.
      You agree not to settle any matter without the prior written consent of
      the Company. The Company will use reasonable efforts to notify you of any
      such claim, action or proceeding upon becoming aware of it.
    </p>
    <br />
    <h2>10. INTERNATIONAL USE</h2>
    <p>
      The Company makes no representation that the Service is accessible,
      appropriate or legally available for use in your jurisdiction, and
      accessing and using the Service is prohibited from territories where doing
      so would be illegal. You access the Service at your own initiative and are
      responsible for compliance with local laws.
    </p>
    <br />
    <h2>11. MANDATORY BINDING ARBITRATION AND CLASS ACTION WAIVER</h2>
    <p>
      11.1. PLEASE READ THIS ARBITRATION PROVISION CAREFULLY TO UNDERSTAND YOUR
      RIGHTS. EXCEPT WHERE PROHIBITED BY LAW, YOU AGREE THAT ANY CLAIM THAT YOU
      MAY HAVE IN THE FUTURE MUST BE RESOLVED THROUGH FINAL AND BINDING
      CONFIDENTIAL ARBITRATION. YOU ACKNOWLEDGE AND AGREE THAT YOU ARE WAIVING
      THE RIGHT TO A TRIAL BY JURY. THE RIGHTS THAT YOU WOULD HAVE IF YOU WENT
      TO COURT, SUCH AS DISCOVERY OR THE RIGHT TO APPEAL, MAY BE MORE LIMITED OR
      MAY NOT EXIST.
    </p>
    <p>
      11.2. YOU AGREE THAT YOU MAY ONLY BRING A CLAIM IN YOUR INDIVIDUAL
      CAPACITY AND NOT AS A PLAINTIFF (LEAD OR OTHERWISE) OR CLASS MEMBER IN ANY
      PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU FURTHER AGREE THAT THE
      ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS OR CLAIMS OR OTHERWISE PRESIDE
      OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING.
    </p>
    <p>
      {" "}
      11.3. YOU AND THE COMPANY, AND EACH OF ITS RESPECTIVE AGENTS, CORPORATE
      PARENTS, SUBSIDIARIES, AFFILIATES, PREDECESSORS IN INTEREST, SUCCESSORS,
      AND ASSIGNS, AGREE TO ARBITRATION (EXCEPT FOR MATTERS THAT MAY BE TAKEN TO
      SMALL CLAIMS COURT), AS THE EXCLUSIVE FORM OF DISPUTE RESOLUTION EXCEPT AS
      PROVIDED FOR BELOW, FOR ALL DISPUTES AND CLAIMS ARISING OUT OF OR RELATING
      TO THIS AGREEMENT, THE SERVICE, OR THE PRIVACY POLICY, UNLESS YOU ARE
      LOCATED IN A JURISDICTION THAT PROHIBITS THE EXCLUSIVE USE OF ARBITRATION
      FOR DISPUTE RESOLUTION.
    </p>
    <p>
      11.4. Arbitration is more informal way to settle disputes than a lawsuit
      in court. A neutral arbitrator instead of a judge or jury is used in
      arbitration, which allows for more limited discovery than in court, and is
      subject to very limited review by courts. The same damages and relief that
      a court can award can be awarded by arbitrators. Please see more
      information about arbitration at
      {" "}
      <a href="http://www.adr.org">http://www.adr.org</a>
      .
    </p>
    <p>
      11.5. A party which intends to seek arbitration must first send to the
      other a written notice of intent to arbitrate (a "Notice") by an
      international courier with a tracking mechanism, or, in the absence of a
      mailing address provided by you to us, via any other method available to
      us, including via e-mail. The Notice to the Company must be addressed to:
      {' '}
      <Address className={styles.address} title="address" />
      {' '}
      (as applicable,
      the "Arbitration Notice Address"). The Notice shall (i) describe the basis
      and nature of the claim or dispute; and (ii) set the specific relief
      sought (the "Demand"). If you and the Company do not reach an agreement to
      resolve the claim within 30 days after the Notice is received, then you or
      we may commence an arbitration proceeding as set forth below or file an
      individual claim in small claims court.
    </p>
    <p>
      11.6. THE AMERICAN ARBITRATION ASSOCIATION ("AAA") WILL EXCLUSIVELY
      ADMINISTER THE ARBITRATION IN ACCORDANCE WITH ITS COMMERCIAL ARBITRATION
      RULES AND THE SUPPLEMENTARY PROCEDURES FOR CONSUMER RELATED DISPUTES (THE
      "Rules"), AS MODIFIED BY THESE TERMS.
    </p>
    <p>
      11.7. If you commence arbitration against us, you are required to provide
      a second Notice to the Company at the Arbitration Notice Address within
      seven (7) days of arbitration commencement. The Rules and AAA forms are
      available online at
      {" "}
      <a href="http://www.adr.org">http://www.adr.org</a>
      .
      Unless your Demand is equal to or greater than $1,000 or was filed in bad
      faith, in which case you are solely responsible for the payment of the
      filing fee, if you are required to pay a filing fee to commence an
      arbitration against us, then we will promptly reimburse you for your
      confirmed payment of the filing fee upon our receipt of the second Notice
      at the Arbitration Notice Address that you have commenced arbitration
      along with a receipt evidencing payment of the filing fee.
    </p>
    <p>
      11.8. The arbitration shall be conducted exclusively in English. A single,
      independent and impartial arbitrator with his or her primary place of
      business in Alexandria, Virginia (if you are from the United States) or in
      Cyprus (if you are not from the United States) will be appointed pursuant
      to the Rules, as modified herein. You and the Company agree to comply with
      the following rules, which are intended to streamline the arbitration
      process and reduce the costs and burdens on the parties: (i) the
      arbitration will be conducted online and/or be solely based on written
      submissions, the specific manner to be chosen by the party initiating the
      arbitration; (ii) the arbitration will not require any personal appearance
      by the parties or witnesses unless otherwise mutually agreed in writing by
      the parties; and (iii) any judgment on the award the arbitrator renders
      may be entered in any court of competent jurisdiction.
    </p>
    <p>
      11.9. TO THE FULLEST EXTENT PERMITTED UNDER LAW, YOU AND THE COMPANY AGREE
      THAT YOU AND THE COMPANY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR
      OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
      PURPORTED CLASS, REPRESENTATIVE, OR CONSOLIDATED PROCEEDING. FURTHER, YOU
      AGREE THAT THE ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS OF MORE THAN ONE
      PERSON’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
      REPRESENTATIVE OR CLASS PROCEEDING, AND THAT IF THIS SPECIFIC PROVISION IS
      FOUND TO BE UNENFORCEABLE, THEN THE ENTIRETY OF THIS MANDATORY ARBITRATION
      SECTION WILL BE NULL AND VOID.
    </p>
    <p>
      11.10. The arbitrator shall have the exclusive and sole authority to
      resolve any dispute relating to the interpretation, construction,
      validity, applicability, or enforceability of these Terms, the Privacy
      Policy, and this arbitration provision. The arbitrator shall have the
      exclusive and sole authority to determine whether this arbitration clause
      can be enforced against a non-party to this agreement and whether a
      non-party to these Terms can enforce its provision against you or us.
    </p>
    <p>
      11.11. Barring extraordinary circumstances, the arbitrator will issue his
      or her final, confidential decision within 120 days from the date the
      arbitrator is appointed. The arbitrator may extend this time limit for an
      additional 30 days upon a showing of good cause and in the interests of
      justice. All arbitration proceedings will be closed to the public and
      confidential, and all records relating thereto will be permanently sealed,
      except as necessary to obtain court confirmation of the arbitration award.
      The award of the arbitrator will be in writing and will include a
      statement setting forth the reasons for the disposition of any claim. The
      arbitrator shall apply the laws of the Commonwealth of Virginia without
      regard to its conflicts of laws principles in conducting the arbitration.
      You acknowledge that these terms and your use of the Service evidences a
      transaction involving interstate commerce. The United States Federal
      Arbitration Act ("FAA") will govern the interpretation, enforcement, and
      proceedings pursuant to this Section 11. Any award rendered shall be
      final, subject to appeal under the FAA.
    </p>
    <p>
      11.12. The abovestated provisions of this Section 11 shall not apply to
      any claim in which either party seeks equitable relief to protect such
      party's copyrights, trademarks, patents, or other intellectual property.
      For the avoidance of doubt, you agree that, in the event the Company or a
      third party breaches these Terms, the damage or harm, if any, caused to
      you will not entitle you to seek injunctive or other equitable relief
      against us, and your only remedy will be for monetary damages, subject to
      the limitations of liability set forth in these Terms.
    </p>
    <p>
      11.13. You and we agree that, notwithstanding any other rights a party may
      have at law or in equity, any claim arising out of or related to these
      Terms (including the Privacy Policy) or the Service, excluding a claim for
      indemnification, must be initiated with the AAA or filed in small claims
      court in Alexandria, Virginia within one (1) year after the claim accrues.
      Otherwise, such cause of action is permanently and forever barred. This
      one (1) year period includes the thirty (30) day pre-dispute procedure set
      forth in sub-clause 11.5 above.
    </p>
    <p>
      11.14. All claims you bring against the Company must be resolved in
      accordance with this Section. All claims filed or brought contrary to this
      Section shall be considered improperly filed. Should you file a claim
      contrary to this Section, the Company may recover attorneys' fees and
      reimbursement of its costs, provided that the Company has notified you in
      writing of the improperly filed claim, and you fail to promptly withdraw
      such claim.
    </p>
    <p>
      11.15. In the event that we make any material change to this arbitration
      provision (other than a change to our Arbitration Notice Address), you may
      reject any such change by sending us written notice to our Arbitration
      Notice Address within thirty (30) days of the change, in which case your
      Account and your license to use the Service will terminate immediately,
      and this Section, as in effect immediately prior to the amendments you
      reject, will survive the termination of these Terms.
    </p>
    <p>
      11.16. If sub-clause 11.9 above or the entirety of this Section 11 is
      found to be unenforceable, then the entirety of this Section 11 will be
      null and void and, in such case, the parties agree that the exclusive
      jurisdiction and venue described in Section 12 will govern any action
      arising out of or related to this Agreement.
    </p>
    <p>
      11.17. YOU UNDERSTAND THAT YOU WOULD HAVE HAD A RIGHT TO LITIGATE THROUGH
      A COURT, TO HAVE A JUDGE OR JURY DECIDE YOUR CASE, AND TO BE PARTY TO A
      CLASS OR REPRESENTATIVE ACTION. HOWEVER, YOU UNDERSTAND AND AGREE TO HAVE
      ANY CLAIMS DECIDED INDIVIDUALLY AND ONLY THROUGH BINDING, FINAL, AND
      CONFIDENTIAL ARBITRATION.
    </p>
    <p>
      11.18. YOU HAVE THE RIGHT TO OPT-OUT OF THIS ARBITRATION PROVISION WITHIN
      THIRTY (30) DAYS FROM THE DATE THAT YOU FIRST USE, OR ATTEMPT TO USE, THE
      SERVICE BY WRITING TO
      {" "}
      <a href="mailto:support@stars-know.com">
        support@stars-know.com
      </a>
      {" "}
      OR TO THE ARBITRATION NOTICE ADDRESS. FOR YOUR OPT-OUT TO BE EFFECTIVE,
      YOU MUST SUBMIT A SIGNED WRITTEN NOTICE OPTING OUT AND CONTAINING ENOUGH
      DETAILS ABOUT YOU FOR US TO BE ABLE TO IDENTIFY YOU WITHIN THIRTY (30)
      DAYS. IF MORE THAN THIRTY (30) DAYS HAVE PASSED, YOU ARE NOT ELIGIBLE TO
      OPT OUT OF THIS PROVISION AND YOU MUST PURSUE YOUR CLAIM THROUGH BINDING
      ARBITRATION AS SET FORTH IN THIS AGREEMENT.
    </p>
    <br />
    <h2>12. GOVERNING LAW</h2>
    <p>
      12.1. The laws of Cyprus, excluding its conflicts of law principles,
      govern these Terms and your use of the Service.
    </p>
    <p>
      12.2. To the extent that any action relating to any dispute hereunder is
      permitted to be brought in a court of law, such action will be subject to
      the exclusive jurisdiction of:
    </p>
    <p>
      12.2.1. the state and federal courts in the City of Alexandria, Virginia –
      if you are a resident of the United States; or
    </p>
    <p>
      12.2.2. the courts of Cyprus – if you are not a resident of the United
      States; and you hereby irrevocably submit to personal jurisdiction and
      venue in such courts, and waive any defense of improper venue or
      inconvenient forum.
      <br />
      <h2>13. MISCELLANEOUS PROVISIONS</h2>
    </p>
    <p>
      13.1. No delay or omission by us in exercising any of our rights occurring
      upon any noncompliance or default by you with respect to these Terms will
      impair any such right or be construed to be a waiver thereof, and a waiver
      by the Company of any of the covenants, conditions or agreements to be
      performed by you will not be construed to be a waiver of any succeeding
      breach thereof or of any other covenant, condition or agreement hereof
      contained.
    </p>
    <p>
      13.2. Subject to Section 11, if any provision of these Terms is found to
      be invalid or unenforceable, then these Terms will remain in full force
      and effect and will be reformed to be valid and enforceable while
      reflecting the intent of the parties to the greatest extent permitted by
      law.
    </p>
    <p>
      13.3. Except as otherwise expressly provided herein, these Terms set forth
      the entire agreement between you and the Company regarding its subject
      matter, and supersede all prior promises, agreements or representations,
      whether written or oral, regarding such subject matter.
    </p>
    <p>
      13.4. The Company may transfer or assign any and all of its rights and
      obligations under these Terms to any other person, by any way, including
      by novation, and by accepting these Terms you give the Company consent to
      any such assignment and transfer. You confirm that placing on the Service
      of a version of these Terms indicating another person as a party to the
      Terms shall constitute valid notice to you of the transfer of Company's
      rights and obligations under the Agreement (unless otherwise is expressly
      indicated).
    </p>
    <p>
      13.5. All information communicated on the Service is considered an
      electronic communication. When you communicate with us through or on the
      Service or via other forms of electronic media, such as e-mail, you are
      communicating with us electronically. You agree that we may communicate
      electronically with you and that such communications, as well as notices,
      disclosures, agreements, and other communications that we provide to you
      electronically, are equivalent to communications in writing and shall have
      the same force and effect as if they were in writing and signed by the
      party sending the communication. You further acknowledge and agree that by
      clicking on a button labeled "SUBMIT", "CONTINUE", "REGISTER", "I AGREE"
      or similar links or buttons, you are submitting a legally binding
      electronic signature and are entering into a legally binding contract. You
      acknowledge that your electronic submissions constitute your agreement and
      intent to be bound by these Terms. YOU HEREBY AGREE TO THE USE OF
      ELECTRONIC SIGNATURES, CONTRACTS, ORDERS AND OTHER RECORDS AND TO
      ELECTRONIC DELIVERY OF NOTICES, POLICIES AND RECORDS OF TRANSACTIONS
      INITIATED OR COMPLETED THROUGH THE SERVICE.
    </p>
    <p>
      13.6. In no event shall the Company be liable for any failure to comply
      with these Terms to the extent that such failure arises from factors
      outside the Company's reasonable control.
    </p>
    <br />
    <h2>14. CONTACT</h2>
    <p>
      If you want to send any notice under these Terms or have any questions
      regarding the Service, you may contact us at: support@stars-know.com.
      I HAVE READ THESE TERMS AND AGREE TO ALL OF THE PROVISIONS CONTAINED
      ABOVE.
    </p>
    <br />
    <p>Last updated: 31 May 2021</p>
    <br />
    <br />
    <h1>IMPORTANT PRIVACY INFORMATION</h1>
    <p>
      When you access our service we automatically collect from your device
      language settings, IP address, time zone, type and model of a device,
      device settings, operating system, Internet service provider, mobile
      carrier, hardware ID, Facebook ID, and other unique identifiers (such as
      IDFA and AAID). We need this data to provide our services, analyze how our
      customers use the service and to measure ads.
    </p>
    <p>
      For improving the product and serving ads, we use third party solutions.
      As a result, we may process data using solutions developed by
      {" "}
      <b>
        Amazon, Snapchat, Facebook, Google, Hotjar, Amplitude, Firebase,
        Pinterest, Snapchat, TikTok
      </b>
      . Therefore, some of the data is stored and processed on the servers of
      such third parties. This enables us to (1) analyze different interactions
      (what products our users have viewed); (2) serve ads (and show them only
      to a particular group of users, for example, only to subscribers).
    </p>
    <p>
      Please read our Privacy Policy below to know more about what we do with
      data (
      <a href="#privacy-policy_3">Section 3</a>
      ), what data privacy rights are available to you (
      <a href="#privacy-policy_6">Section 6</a>
      ) and who will be the data
      controller (
      <a href="#privacy-policy_1">Section 1</a>
      ). If any questions will remain unanswered, please contact us at
      support@stars-know.com.
    </p>
    <ScrollableAnchor id="privacy-policy">
      <h1>PRIVACY POLICY</h1>
    </ScrollableAnchor>
    <p>
      This Privacy Policy explains what personal data is collected when you use
      the Nebula mobile application (the “App”), Nebula website located at
      {" "}
      <a href="https://stars-know.com/">https://stars-know.com/</a>
      {" "}
      (the “Website”),
      the services and products provided through it (together Website with the
      App, the “Service”), how such personal data will be processed.
    </p>
    <p>
      BY USING THE SERVICE, YOU PROMISE US THAT (I) YOU HAVE READ, UNDERSTAND
      AND AGREE TO THIS PRIVACY POLICY, AND (II) YOU ARE OVER 18 YEARS OF AGE
      (OR HAVE HAD YOUR PARENT OR GUARDIAN READ AND AGREE TO THIS PRIVACY POLICY
      FOR YOU). If you do not agree, or are unable to make this promise, you
      must not use the Service. In such case, you must (a) contact us and
      request deletion of your data; and (b) cancel any subscriptions using the
      functionality provided by Apple (if you are using iOS) or Google (if you
      are using Android), any other app stores that may be available from time
      to time, or instructions on the Website; (c) delete the App from your
      devices; (d) leave the Website and not access or use it.
    </p>
    <p>
      “GDPR” means the General Data Protection Regulation (EU) 2016/679 of the
      European Parliament and of the Council of 27 April 2016 on the protection
      of natural persons with regard to the processing of personal data and on
      the free movement of such data.
    </p>
    <p>
      “EEA” includes all current member states to the European Union and the
      European Free Trade Association. For the purpose of this policy EEA shall
      include the United Kingdom of Great Britain and Northern Ireland.
      “Process”, in respect of personal data, includes to collect, store, and
      disclose to others.
      <h2>TABLE OF CONTENTS</h2>
    </p>
    <p>
      <a href="#privacy-policy_1">1. PERSONAL DATA CONTROLLER</a>
    </p>
    <p>
      <a href="#privacy-policy_2">2. CATEGORIES OF PERSONAL DATA WE COLLECT</a>
    </p>
    <p>
      <a href="#privacy-policy_3">
        3. FOR WHAT PURPOSES WE PROCESS PERSONAL DATA
      </a>
    </p>
    <p>
      <a href="#privacy-policy_4">
        4. UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA (Applies only to
        EEA-based users)
      </a>
    </p>
    <p>
      <a href="#privacy-policy_5">5. WITH WHOM WE SHARE YOUR PERSONAL DATA</a>
    </p>
    <p>
      <a href="#privacy-policy_6">
        6. HOW YOU CAN EXERCISE YOUR PRIVACY RIGHTS
      </a>
    </p>
    <p>
      <a href="#privacy-policy_7">7. AGE LIMITATION</a>
    </p>
    <p>
      <a href="#privacy-policy_8">8. INTERNATIONAL DATA TRANSFERS</a>
    </p>
    <p>
      <a href="#privacy-policy_9">9. CHANGES TO THIS PRIVACY POLICY</a>
    </p>
    <p>
      <a href="#privacy-policy_10">10. CALIFORNIA PRIVACY RIGHTS</a>
    </p>
    <p>
      <a href="#privacy-policy_11">11. DATA RETENTION</a>
    </p>
    <p>
      <a href="#privacy-policy_12">
        12. HOW “DO NOT TRACK” REQUESTS ARE HANDLED
      </a>
    </p>
    <p>
      <a href="#privacy-policy_13">13. CONTACT US</a>
    </p>
    <h2 id="privacy-policy_1">1. PERSONAL DATA CONTROLLER</h2>
    <p>
      Obrio Limited, a company registered under the laws of the Republic of Cyprus,
      having its registered office at
      {' '}
      <Address className={styles.address} title="address" />
      {' '}
      will be the controller of your personal data.
    </p>
    <br />
    <h2 id="privacy-policy_2">2. CATEGORIES OF PERSONAL DATA WE COLLECT</h2>
    <p>
      We collect data you give us voluntarily (for example, name or email
      address). We also collect data automatically (for example, your IP
      address).
      <br />
    </p>
    <p>
      2.1. Data you give us
      <br />
      {" "}
      We will ask you to provide your date of birth, city of birth,
      gender, name, relationship status to provide the service. You can provide
      us your time of birth if you know it and want to share. We can also
      receive your email address when you contact our support team, when you
      enter it to receive a purchase, or when you subscribe for a newsletter.
      <br />
      {" "}
      You can also provide information about certain aspects of your life
      to receive an answer to your questions from the astrologer. We will use it
      to provide the Service. When you decide to receive a relationship guide,
      we will ask you to answer questions relating to your relationship
      experience.
      <br />
    </p>
    <p>
      2.2. Data we collect automatically:
      <br />
    </p>
    <p>
      2.2.1. Data about how you found us
      <br />
      {" "}
      We collect data about your referring app or URL (that is, the app
      or place on the Web where you were when you tapped on our ad).
      <br />
    </p>
    <p>
      2.2.2. Device and Location data.
      <br />
      {" "}
      We collect data from your mobile device. Examples of such data
      include: language settings, IP address, time zone, type and model of a
      device, device settings, operating system, Internet service provider,
      mobile carrier, hardware ID, and Facebook ID.
      <br />
    </p>
    <p>
      2.3. Usage data
      <br />
      {" "}
      We record how you interact with our Service. For example, we log
      your taps/clicks on certain areas of the interface, the features, and
      content you interact with, how often you use the Service, how long you are
      in the Service, your purchase orders.
      <br />
    </p>
    <p>
      2.4. Advertising IDs
      <br />
      {" "}
      We collect your Apple Identifier for Advertising (“IDFA”) or Google
      Advertising ID (“AAID”) (depending on the operating system of your
      device). You can typically reset these numbers through the settings of
      your device’s operating system (but we do not control this).
      <br />
      <h2 id="privacy-policy_3">
        {" "}
        3. FOR WHAT PURPOSES WE PROCESS YOUR PERSONAL DATA
      </h2>
      We process your personal data:
    </p>
    <p>
      3.1. To provide our Service
      <br />
      {" "}
      This includes enabling you to use the Service in a seamless manner
      and preventing or addressing Service errors or technical issues.
      <br />
      To host personal data and enable the Service to operate and be distributed
      we use Amazon Web Services (AWS), which is a hosting and backend service
      provided by Amazon.
      <br />
    </p>
    <p>
      3.2. To customize your experience
      <br />
      {" "}
      We process your personal data to provide you with the personalized
      service and make offers tailored to your astrological sign.
      <br />
    </p>
    <p>
      3.3. To provide you with customer support
      <br />
      {" "}
      We process your personal data to respond to your requests for
      technical support, Service information or to any other communication you
      initiate. For this purpose, we may send you emails about, for example, the
      performance of our Service, payment transactions, notices regarding our
      Terms and Conditions of Use, this Privacy Policy or other documents that
      we make available from time to time.
      <br />
    </p>
    <p>
      3.4. To communicate with you regarding your use of our Service
      <br />
      {" "}
      We communicate with you, for example, by push notifications. These
      may include horoscope reminders, motivational messages or other
      information about the Service. As a result, you may, for example, receive
      a push notification every day at a particular time reminding you to check
      your horoscope. To opt out of receiving push notifications, you need to
      change the settings on your device. We can also communicate with you via
      email when you make a purchase on the Website. These may include emails
      with information about the Service.
      <br />
      The services that we use for these purposes may collect data concerning
      the date and time when the message was viewed by our Services’ users, as
      well as when they interacted with it, such as by tapping/clicking on links
      included in the message.
      <br />
    </p>
    <p>
      3.5. To research and analyze your use of the Service
      <br />
      {" "}
      This helps us to better understand our business, analyze our
      operations, maintain, improve, innovate, plan, design, and develop the
      Service and our new products. We also use such data for statistical
      analysis purposes, to test and improve our offers. This enables us to
      better understand what categories of users use our Services. As a
      consequence, we often decide how to improve the Service based on the
      results obtained from this processing.
      <br />
      We use Facebook Analytics, which is a service provided by Facebook that
      allows us to use different analytical tools. On Facebook Analytics we get,
      in particular, aggregated demographics and insights on how many people
      launch our App, how often users make purchases, and other interactions.
      Learn more about Facebook’s approach to data from its Privacy Policy.
      <br />
      {" "}
      Amplitude is an analytics service provided by Amplitude Inc. We use
      this tool to understand how customers use our Service. Amplitude collects
      various technical information, in particular, time zone, type of device
      (phone, tablet or laptop), unique identifiers (including advertising
      identifiers). Amplitude also allows us to track various interactions that
      occur in our App. As a result, Amplitude helps us to decide what features
      we should focus on. Amplitude provides more information on how they
      process data in its Privacy Policy.
      <br />
      {" "}
      We also use Firebase Analytics, which is an analytics service
      provided by Google. In order to understand Google's use of data, consult
      Google's partner policy. Firebase Privacy information. Google’s Privacy
      Policy.
      <br />
      {" "}
      We also use Hotjar, a behavior analytics tool that analyses Website
      use, providing feedback through tools such as heatmaps, session
      recordings, and surveys. Hotjar’s Privacy Policy.
      <br />
    </p>
    <p>
      3.6. To send you marketing communications
      <br />
      {" "}
      We may show you advertisements on our Service, send you push
      notifications or emails for marketing purposes. To opt out of receiving
      push notifications from us, you need to change the settings on your
      device. To opt out of receiving marketing emails, you need to unsubscribe
      following instructions in the footer of the marketing emails.
      <br />
    </p>
    <p>
      3.7. To personalize our ads
      <br />
      {" "}
      <br />
      We and our partners use your personal data to tailor ads and possibly even
      show them to you at the relevant time. For example, if you have installed
      our App, you might see ads of our products in your Facebook’s feed.
      <br />
      <br />
      How to opt out or influence personalized advertising
      <br />
      {" "}
      <br />
      iOS: On your iPhone or iPad, go to “Settings,” then “Privacy” and tap
      “Advertising” to select “Limit Ad Track”. In addition, you can reset your
      advertising identifier (this also may help you to of personalized ads) in
      the same section.
      <br />
      {" "}
      <br />
      Android: To opt-out of ads on an Android device, simply open the Google
      Settings app on your mobile phone, tap “Ads” and enable “Opt out of
      interest-based ads”. In addition, you can reset your advertising
      identifier in the same section (this also may help you to of personalized
      ads).
      <br />
      {" "}
      <br />
      To learn even more about how to affect advertising choices on various
      devices, please look at the information available here.
      <br />
      {" "}
      <br />
      In addition, you may get useful information and opt out of some
      interest-based advertising, by visiting the following links:
      <br />
      {" "}
      <br />
      • Network Advertising Initiative – http://optout.networkadvertising.org/
      <br />
      {" "}
      <br />
      • Digital Advertising Alliance – http://optout.aboutads.info/
      <br />
      {" "}
      <br />
      • Digital Advertising Alliance (Canada) – http://youradchoices.ca/choices
      <br />
      {" "}
      <br />
      • Digital Advertising Alliance (EU) – http://www.youronlinechoices.com/
      <br />
      {" "}
      <br />
      • DAA AppChoices page – http://www.aboutads.info/appchoices
      <br />
      <br />
      Browsers: It is also may be possible to stop your browser from accepting
      cookies altogether by changing your browser’s cookie settings. You can
      usually find these settings in the “options” or “preferences” menu of your
      browser. The following links may be helpful, or you can use the “Help”
      option in your browser. • Cookie settings in Internet Explorer • Cookie
      settings in Firefox • Cookie settings in Chrome • Cookie settings in
      Safari web and iOS Google allows its users to opt out of Google’s
      personalized ads and to prevent their data from being used by Google
      Analytics.
      <br />
      We value your right to influence the ads that you see, thus we are letting
      you know what service providers we use for this purpose and how some of
      them allow you to control your ad preferences.
      <br />
      {" "}
      We use Facebook Ads Manager together with Facebook Custom Audience,
      which allows us to choose audiences that will see our ads on Facebook or
      other Facebook’s products (for example, Instagram). Through Facebook
      Custom Audience we may create a list of users with certain sets of data,
      such as an IDFA, choose users that have completed certain actions in the
      Service (for example, installed our App or visited the Website). As a
      result, we may ask Facebook to show some ads to a particular list of
      users. As a result, more of our ads may show up while you are using
      Facebook or other Facebook’s products (for example, Instagram). You may
      learn how to opt out of advertising provided to you through Facebook
      Custom Audience here.
      <br />
      {" "}
      Facebook also allows its users to influence the types of ads they
      see on Facebook. To find how to control the ads you see on Facebook,
      please go here or adjust your ads settings on Facebook.
      <br />
      {" "}
      Google Ads is an ad delivery service provided by Google that can
      deliver ads to users. In particular, Google allows us to tailor the ads in
      a way that they will appear, for example, only to users that have
      conducted certain actions with our Service (for example, show our ads to
      users who have made a purchase). Some other examples of events that may be
      used for tailoring ads include, in particular, installing our App,
      visiting our Website. Google allows its users to opt out of Google’s
      personalized ads and to prevent their data from being used by Google
      Analytics.
      <br />
      {" "}
      We also use Snapchat Advertising Platform together with Snapchat
      Audience Based Match, which is an ad delivery service provided by Snapchat
      that can link the activity of some users of our App with the Snapchat
      advertising network and show some of our ads to them. As a result, you may
      see more of ads on Snapchat in case you use our App. Snapchat allows you
      to Opt Out of their audience based ads. Privacy Policy.
      <br />
      {" "}
      TikTok Ads is the service provided by TikTok that can deliver ads
      to its users. The ads can be tailored to specific categories of users (for
      instance, based on their geographical location). TikTok’s Privacy Policy.
      <br />
      {" "}
      We use Pinterest Ads to deliver group-based advertisements. For
      example, you may see our ads if you are interested in specific services,
      information, or offers. Pinterest Privacy Policy.
      <br />
    </p>
    <p>
      3.8. To process your payments
      <br />
      {" "}
      We provide paid products and/or services within the Service. For
      this purpose, we use third-party services for payment processing (for
      example, payment processors). As a result of this processing, you will be
      able to make a payment and we will be notified that the payment has been
      made. We will not store or collect your payment card details ourselves.
      This information will be provided directly to our third-party payment
      processors.
      <br />
    </p>
    <p>
      3.9. To enforce our Terms and Conditions of Use and to prevent and combat
      fraud
      <br />
      {" "}
      We use personal data to enforce our agreements and contractual
      commitments, to detect, prevent, and combat fraud. As a result of such
      processing, we may share your information with others, including law
      enforcement agencies (in particular, if a dispute arises in connection
      with our Terms and Conditions of Use).
      <br />
    </p>
    <p>
      3.10. To comply with legal obligations
      <br />
      {" "}
      We may process, use, or share your data when the law requires it,
      in particular, if a law enforcement agency requests your data by available
      legal means.
      <br />
    </p>
    <h2 id="privacy-policy_4">
      {" "}
      4. UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA (applies only to
      EEA-based users)
    </h2>
    <p>
      {" "}
      In this section, we are letting you know what legal basis we use for each
      particular purpose of processing. For more information on a particular
      purpose, please refer to Section 3. This section applies only to EEA-based
      users. We process your personal data under the following legal bases:
    </p>
    <p>
      4.1. to perform our contract with you;
      <br />
      {" "}
      Under this legal basis we: • Provide our Service (in accordance
      with our Terms and Conditions of Use) • Customize your experience •
      Provide you with customer support • Communicate with you regarding your
      use of our Service • Process your payments
      <br />
    </p>
    <p>
      4.2. for our (or others') legitimate interests, unless those interests are
      overridden by your interests or fundamental rights and freedoms that
      require protection of personal data;
      <br />
      {" "}
      We rely on legitimate interests:
      <br />
      {" "}
      <br />
      • to communicate with you regarding your use of our Service
      <br />
      {" "}
      <br />
      This includes, for example, sending you push notification reminding you to
      use the Service. The legitimate interest we rely on for this purpose is
      our interest to encourage you to use our Service more often.
      <br />
      {" "}
      <br />
      • to research and analyze your use of the Service
      <br />
      {" "}
      <br />
      Our legitimate interest for this purpose is our interest in improving our
      Service so that we understand users’ preferences and are able to provide
      you with a better experience (for example, to make the use of the Service
      easier and more enjoyable, or to introduce and test new features).
      <br />
      {" "}
      <br />
      • to personalize our ads
      <br />
      {" "}
      <br />
      The legitimate interest we rely on for this processing is our interest to
      promote our Service in a reasonably targeted way.
      <br />
      {" "}
      <br />
      • to enforce our Terms and Conditions of Use and to prevent and combat
      fraud
      <br />
      {" "}
      <br />
      Our legitimate interests for this purpose are enforcing our legal rights,
      preventing and addressing fraud and unauthorised use of the Service,
      non-compliance with our Terms and Conditions of Use.
    </p>
    <p>4.3. to comply with legal obligations.</p>
    <br />
    <h2 id="privacy-policy_5"> 5. WITH WHOM WE SHARE YOUR PERSONAL DATA</h2>
    <p>
      {" "}
      We share information with third parties that help us operate, provide,
      improve, integrate, customize, support, and market our Service. We may
      share some sets of personal data, in particular, for purposes and with
      parties indicated in Section 3 of this Privacy Policy. The types of third
      parties we share information with include, in particular:
    </p>
    <p>
      5.1. Service providers
      <br />
      {" "}
      <br />
      We share personal data with third parties that we hire to provide services
      or perform business functions on our behalf, based on our instructions. We
      may share your personal information with the following types of service
      providers:
      <br />
      {" "}
      • data storage providers (Amazon) • data analytics providers
      (Facebook, Google, Firebase, Amplitude, Hotjar) • message sending and
      notifications service providers (Firebase) • marketing partners (in
      particular, social media networks, marketing agencies, email delivery
      services; Facebook, Google, Snapchat, Pinterest, TikTok, Sendios) •
      payment service providers (Solid)
      <br />
    </p>
    <p>
      5.2. Law enforcement agencies and other public authorities
      <br />
      {" "}
      <br />
      We may use and disclose personal data to enforce our Terms and Conditions
      of Use, to protect our rights, privacy, safety, or property, and/or that
      of our affiliates, you or others, and to respond to requests from courts,
      law enforcement agencies, regulatory agencies, and other public and
      government authorities, or in other cases provided for by law.
      <br />
    </p>
    <p>
      5.3. Third parties as part of a merger or acquisition
      <br />
      {" "}
      <br />
      As we develop our business, we may buy or sell assets or business
      offerings. Customers’ information is generally one of the transferred
      business assets in these types of transactions. We may also share such
      information with any affiliated entity (e.g. parent company or subsidiary)
      and may transfer such information in the course of a corporate
      transaction, such as the sale of our business, a divestiture, merger,
      consolidation, or asset sale, or in the unlikely event of bankruptcy.
    </p>
    <br />
    <h2 id="privacy-policy_6">6. HOW YOU CAN EXERCISE YOUR RIGHTS</h2>
    To be in control of your personal data, you have the following rights:
    Accessing / reviewing / updating / correcting your personal data. You may
    review, edit, or change the personal data that you had previously provided
    on the Service. Deleting your personal data. You can request erasure of your
    personal data as permitted by law. When you request deletion of your
    personal data, we will use reasonable efforts to honor your request. In some
    cases, we may be legally required to keep some of the data for a certain
    time; in such event, we will fulfill your request after we have complied
    with our obligations. Objecting to or restricting the use of your personal
    data. You can ask us to stop using all or some of your personal data or
    limit our use thereof. Additional information for EEA-based users:
    <br />
    {" "}
    If you are based in the EEA, you have the following rights in
    addition to the above:
    <br />
    {" "}
    The right to lodge a complaint with supervisory authority. We would
    love you to contact us directly, so we could address your concerns.
    Nevertheless, you have the right to lodge a complaint with a competent data
    protection supervisory authority, in particular in the EU Member State where
    you reside, work or where the alleged infringement has taken place.
    <br />
    {" "}
    The right to data portability. If you wish to receive your personal
    data in a machine-readable format, you can send respective request to us as
    described below. To exercise any of your privacy rights, please send a
    request to support@stars-know.com.
    <h2 id="privacy-policy_7">7. AGE LIMITATION</h2>
    <p>
      We do not knowingly process personal data from persons under 18 years of
      age. If you learn that anyone younger than 18 has provided us with
      personal data, please contact us.
    </p>
    <h2 id="privacy-policy_8">8. INTERNATIONAL DATA TRANSFERS</h2>
    <p>
      We may transfer personal data to countries other than the country in which
      the data was originally collected in order to provide the Service set
      forth in the Terms and Conditions of Use and for purposes indicated in
      this Privacy Policy. If these countries do not have the same data
      protection laws as the country in which you initially provided the
      information, we deploy special safeguards. In particular, if we transfer
      personal data originating from the EEA to countries with not adequate
      level of data protection, we use one of the following legal bases: (i)
      Standard Contractual Clauses approved by the European Commission (details
      available here), or (ii) the European Commission adequacy decisions about
      certain countries (details available here).
    </p>
    <h2 id="privacy-policy_9"> 9. CHANGES TO THIS PRIVACY POLICY</h2>
    <p>
      We may modify this Privacy Policy from time to time. If we decide to make
      material changes to this Privacy Policy, you will be notified by available
      means such as email and will have an opportunity to review the revised
      Privacy Policy. By continuing to access or use the Service after those
      changes become effective, you agree to be bound by the revised Privacy
      Policy.
    </p>
    <h2 id="privacy-policy_10">10. CALIFORNIA PRIVACY RIGHTS</h2>
    <p>
      California’s Shine the Light law gives California residents the right to
      ask companies once a year what personal information they share with third
      parties for those third parties' direct marketing purposes. Learn more
      about what is considered to be personal information under the statute. To
      obtain this information from us, please send an email message to
      {" "}
      <a href="mailto:support@stars-know.com">
        support@stars-know.com
      </a>
      {" "}
      which includes “Request for California Privacy Information” on the subject
      line and your state of residence and email address in the body of your
      message. If you are a California resident, we will provide the requested
      information to you at your email address in response.
    </p>
    <h2 id="privacy-policy_11">11. DATA RETENTION</h2>
    <p>
      We will store your personal data for as long as it is reasonably necessary
      for achieving the purposes set forth in this Privacy Policy (including
      providing the Service to you). We will also retain and use your personal
      data as necessary to comply with our legal obligations, resolve disputes,
      and enforce our agreements.
    </p>
    <h2 id="privacy-policy_12">12. HOW “DO NOT TRACK” REQUESTS ARE HANDLED</h2>
    <p>
      Except as otherwise stipulated in this Privacy Policy, this App does not
      support “Do Not Track” requests. To determine whether any of the
      third-party services it uses honor the “Do Not Track” requests, please
      read their privacy policies.
    </p>
    <h2 id="privacy-policy_13">13. CONTACT US</h2>
    <p>
      You may contact us at any time for details regarding this Privacy Policy
      and its previous versions. For any questions concerning your personal data
      please contact us at support@stars-know.com.
    </p>
    <p>Last updated: 31 May 2021</p>
    <br />
    <br />
    <div id="subscription-policy">
      <h1>SUBSCRIPTION TERMS</h1>
    </div>
    <h2>
      <b>1. Trial</b>
    </h2>
    <p>
      We may offer a trial subscription for service. Unless you request cancellation at least
      24 hours before the end of the trial, you will be automatically charged a
      price indicated on the payment screen for a chosen subscription period.
    </p>
    <h2>
      <b>2. Subscription</b>
    </h2>
    <p>
      The subscription renews automatically at the end of each period (each
      week, month, 6 months, year, or otherwise, depending on the option
      selected by you at the time of purchase) until you cancel.
    </p>
    <h2>
      <b>3. Payment method</b>
    </h2>
    <p>
      Payment will be charged to the payment method you submitted at the time of
      purchase at confirmation of purchase. You authorize us to charge the
      applicable fees to the payment method that you submit.
    </p>
    <h2>
      <b>4. Cancellation</b>
    </h2>
    <p>
      Cancelling your subscription means that the automatic renewal will be
      disabled, but you will still have access to all your subscription features
      for the remaining time of then-current period. Note that deleting the app
      does not cancel your subscriptions.
    </p>
    <p>
      <b>If you purchased a subscription or enabled trial on App Store:</b>
      {" "}
      You
      can cancel a trial or a subscription anytime by turning-off autorenewal
      through your Apple ID account settings. To avoid being charged, cancel the
      subscription in your Apple ID account settings at least 24 hours before
      the end of the trial or then-current subscription period. You alone can
      manage your subscriptions. Learn more about managing subscriptions (and
      how to cancel them) on Apple support page.
    </p>
    <p>
      <b>If you purchased a subscription or enabled trial on Google Play:</b>
      {" "}
      You can cancel a trial or a subscription anytime by turning off
      auto-renewal through your Google Play account settings. To avoid being
      charged, cancel the subscription in your account settings at least 24
      hours before the end of the trial or then-current subscription period. You
      alone can manage your subscriptions. Learn more about managing
      subscriptions (and how to cancel them) on Google’s support page.
    </p>
    <p>
      <b>If you purchased a subscription or enabled trial on our website:</b>
      {" "}
      You can cancel a trial or a subscription by contacting support team at
      {" "}
      <a href="mailto:support@stars-know.com">
        support@stars-know.com
      </a>
      {" "}
      at least 24 hours before the end of the trial or then-current subscription
      period.
    </p>
    <h2>
      <b>5. Changes</b>
    </h2>
    <p>
      To the maximum extent permitted by applicable laws, we may change
      subscription fees at any time. We will give you reasonable notice of any
      such pricing changes by posting the new prices on the app and/or by
      sending you an email notification, or in other prominent way. If you do
      not wish to pay the new fees, you can cancel the applicable subscription
      prior to the change going into effect.
    </p>
    <h2>
      <b>6. Refunds</b>
    </h2>
    <p>
      <b>If you purchased a subscription or enabled trial on App Store:</b>
      {" "}
      If
      you are eligible for a refund, you’ll have to request it directly from
      Apple. To request a refund, follow these instructions from the Apple
      support page.
    </p>
    <p>
      <b>If you purchased a subscription or enabled trial on Google Play:</b>
      {" "}
      If
      you are eligible for a refund, you'll have to request it directly from
      Google. To request a refund, follow these instructions from the Google’s
      support page.
    </p>
    <p>
      <b>If you purchased a subscription or enabled trial on our website:</b>
      {" "}
      Please refer to our Money-back guarantee policy/Terms and Conditions of
      Use.
    </p>
    <h2>
      <b>7. Contacts</b>
    </h2>
    <p>
      If you have any questions in relation to the subscription terms, please
      contact us at support@stars-know.com.
    </p>
    <p>
      Please take a screenshot of this information for your reference. This may
      help you to control your subscriptions.
    </p>
    <p>Last updated: 31 May 2021</p>
    <br />
    <br />
    <div id="money-back-policy">
      <h1>MONEY-BACK GUARANTEE POLICY</h1>
    </div>
    <h2>
      <b>I. MONEY-BACK GUARANTEE RULES</b>
    </h2>
    <p>
      In addition to refund rights available under applicable laws, you are
      eligible to receive a refund if you are not satisfied with information
      included in the report, consider it incomplete, deceiving, inaccurate or
      incorrect, and meet all of the following conditions:
      <br />
      - you contact us via
      {" "}
      <a href="mailto:support@stars-know.com">
        support@stars-know.com
      </a>
      {" "}
      within 30 days after you placed an order and purchased our customised
      horoscope & compatibility report; and
      <br />
      - you elaborate on the reason why you consider our report to be
      incomplete, deceiving, inaccurate or incorrect, as well as provide
      evidence and references that support your arguments.
      <br />
      We will review your application and notify you (by email) whether your
      application is approved. If the application is approved, your refund will
      be processed, and a credit will be automatically applied to your credit
      card or original method of payment.
    </p>
    <h2>
      <b>II. GENERAL REFUND RULES</b>
    </h2>
    <p>
      Generally, if you do not meet the conditions of our Money-back Guarantee
      set out above, the fees you have paid are non-refundable and/or
      non-exchangeable, unless otherwise stated herein or is required by
      applicable law.
    </p>
    <p>
      <b>Note for the EU residents:</b>
      {" "}
      If you are an EU resident, you have the
      right to withdraw from agreement for purchase of digital content without
      charge and without giving any reason within fourteen (14) days from the
      date of such agreement conclusion. The withdrawal right does not apply if
      the performance of the agreement has begun with your prior express consent
      and your acknowledgment that you thereby lose your right of withdrawal.
      YOU HEREBY EXPRESSLY CONSENT TO THE IMMEDIATE PERFORMANCE OF THE AGREEMENT
      AND ACKNOWLEDGE THAT YOU WILL LOSE YOUR RIGHT OF WITHDRAWAL FROM THE
      AGREEMENT ONCE OUR SERVERS VALIDATE YOUR PURCHASE AND THE APPLICABLE
      PURCHASE IS SUCCESSFULLY DELIVERED TO YOU. Therefore, you will not be
      eligible for a refund, unless the digital content is defective.
    </p>
    <p>Last updated: 31 May 2021</p>
  </div>
);

export default Terms;
