import React from 'react';
import { ReactComponent as Img } from 'src/assets/images/postQuiz/img-offer-2.svg';
import styles from './Bonus.module.scss';

const Bonus = () => (
  <div className={styles.container}>
    <h3 className={styles.title}>We have a FREE bonus for you</h3>
    <h3 className={styles.title}>
      Full astrology horoscope for entire 2022 year for CANCER inside
    </h3>
    <Img className={styles.img} title="Offer" />
  </div>
);

export default Bonus;
