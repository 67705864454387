import React from 'react';
import { ReactComponent as Img } from 'src/assets/images/quiz/details.svg';
import QuizContent from 'src/components/QuizContent/QuizContent';
import RadioChoice from 'src/components/RadioChoice/RadioChoice';
import { ROUTES } from '../../constants/routes';
import PensiveImg from '../../assets/images/emojies/pensive.svg';
import NeutralImg from '../../assets/images/emojies/neutral.svg';
import RelievedImg from '../../assets/images/emojies/relieved.svg';

const choices = [
  { text: 'Strongly agree', img: PensiveImg, id: 1 },
  { text: 'Somewhat disagree', img: NeutralImg, id: 2 },
  { text: 'Disagree', img: RelievedImg, id: 3 },
];

const Details = () => (
  <QuizContent
    title="Would you describe yourself as a trustworthy?"
    img={<Img title="details" />}
    radio
    step={3}
    routeBack={ROUTES.OVERTHINK}
  >
    <RadioChoice route={ROUTES.TRUST} choices={choices} name="details" />
  </QuizContent>
);
export default Details;
